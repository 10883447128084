import React, { useRef, useEffect } from "react";
import ChatWindow from "./ChatWindow";
import "./styles/ChatContainer.css";

const ChatContainer = ({
  showCenterLogo,
  messages,
  isLoading,
  suggestions,
  handleSuggestionClick,
  greetingMessage,
}) => {
  const animatedMessageRef = useRef(null);

  useEffect(() => {
    if (animatedMessageRef.current) {
      const words = greetingMessage.trim().split(" ");
      let currentWord = 0;
      let message = "";

      const timer = setInterval(() => {
        if (currentWord < words.length) {
          if (words[currentWord]) {
            message += words[currentWord];
            if (currentWord < words.length - 1) {
              message += " ";
            }
            animatedMessageRef.current.innerText = message;
          }
          currentWord++;
        } else {
          clearInterval(timer);
        }
      }, 100);

      return () => clearInterval(timer);
    }
  }, [greetingMessage]);

  useEffect(() => {
    if (animatedMessageRef.current) {
      animatedMessageRef.current.innerText = "";
    }
  }, [animatedMessageRef]);

  return (
    <div className="chat-container">
      {showCenterLogo ? (
        <div className="logo-container">
          <div className="center-logo-intro">
            <h3 className="text-adam" ref={animatedMessageRef} />
            <div className="suggestions-container">
              <div className="suggestions-title">Suggestions:</div>
              {suggestions.length > 0 && (
                <div className="suggestions-row">
                  {suggestions.slice(0, 2).map((suggestion, index) => (
                    <button
                      type="button"
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                      className="suggestion-button"
                    >
                      {suggestion}
                    </button>
                  ))}
                </div>
              )}
              {suggestions.length > 2 && (
                <div className="suggestions-row">
                  {suggestions.slice(2).map((suggestion, index) => (
                    <button
                      type="button"
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                      className="suggestion-button"
                    >
                      {suggestion}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="chat-window-container">
          <ChatWindow messages={messages} isLoading={isLoading} />
        </div>
      )}
    </div>
  );
};

export default ChatContainer;
