import React, { useCallback } from "react";
import logosvg from "../images/logo.svg";
import reset from "../images/reset.svg";
import "./styles/Chatinput.css";

const ChatInput = ({
  userInput = "",
  handleInputChange,
  handleSendClick,
  handleReset,
}) => {
  const handleInputKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSendClick({ type: "text", payload: userInput });
      }
    },
    [handleSendClick, userInput]
  );

  const onSendClick = () => {
    handleSendClick({ type: "text", payload: userInput });
  };

  return (
    <div className="chat-input">
      <div className="messageBox">
        <img
          src={logosvg}
          alt="Avatar"
          className="avatar"
          style={{ margiRight: "0.5rem" }}
        />

        <div className="reset">
          <img
            src={reset}
            alt="Reset conversation"
            className="avatar"
            style={{ 
              marginLeft: "0.5rem",
              transition: "all 0.2s ease",
              padding: "3px",
              borderRadius: "5px",
            }}
            onClick={handleReset}
          />
          <span className="reset-tooltip">Reset conversation</span>
        </div>

        <input
          required=""
          placeholder="Chat with adam..."
          type="text"
          id="messageInput"
          value={userInput}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
        />

        <button
          id="sendButton"
          type="button"
          onClick={onSendClick}
          disabled={!userInput.trim()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 664 663"
          >
            <path
              fill="none"
              d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
            ></path>
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="33.67"
              stroke="#6c6c6c"
              d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ChatInput;
