import React, { useRef, useEffect } from "react";
import "./styles/Chatwindow.css";
import logosvg from "../images/logo.svg";
import userIcon from "../images/icons-user.png";

const parseMarkdown = (text) => {
  if (!text) {
    return [];
  }

  // Enhanced regex to handle more URL patterns
  const parts = text.split(/(\*\*[^*]+\*\*|\[.+?\]\(.+?\)|\bhttps?:\/\/\S+|\#{2,3} .+)/g);

  return parts.map((part, index) => {
    if (part.startsWith("**") && part.endsWith("**")) {
      return <strong key={index}>{part.slice(2, -2)}</strong>;
    } else if (part.startsWith("### ")) {
      return <h3 key={index}>{part.slice(4)}</h3>;
    } else if (part.startsWith("## ")) {
      return <h2 key={index}>{part.slice(3)}</h2>;
    } else if (part.match(/\[.+?\]\(.+?\)/)) {
      const match = /\[(.+?)\]\((.+?)\)/.exec(part);
      if (match) {
        const [_, text, url] = match;
        const isValidUrl = url.match(/^https?:\/\//i);
        return isValidUrl ? (
          <a href={url} key={index} target="_blank" rel="noopener noreferrer">{text}</a>
        ) : part;
      }
    } else if (part.match(/^https?:\/\/\S+/i)) {
      return <a href={part.trim()} key={index} target="_blank" rel="noopener noreferrer">{part.trim()}</a>;
    }
    return part;
  });
};

const TypingIndicator = () => (
  <div className="typing-indicator">
    <span></span>
    <span></span>
    <span></span>
  </div>
);

const ChatWindow = ({ messages = [], isLoading }) => {
  const chatWindowRef = useRef(null);
  const lastMessageRef = useRef(null);

  useEffect(() => {
    if (chatWindowRef.current) {
      const shouldScroll = 
        chatWindowRef.current.scrollHeight - 
        chatWindowRef.current.scrollTop <= 
        chatWindowRef.current.clientHeight + 100;

      if (shouldScroll) {
        chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
      }
    }
  }, [messages]);

  useEffect(() => {
    if (lastMessageRef.current) {
      const shouldScroll = 
        chatWindowRef.current.scrollHeight - 
        chatWindowRef.current.scrollTop <= 
        chatWindowRef.current.clientHeight + 100;

      if (shouldScroll) {
        lastMessageRef.current.scrollIntoView({ 
          behavior: "smooth",
          block: "end"
        });
      }
    }
  }, [messages]);

  return (
    <div className="chat-window" ref={chatWindowRef}>
      {messages.map((message, index) => (
        <div
          key={index}
          className={`message ${message.sender === "user" ? "received" : "sent"}`}
          ref={index === messages.length - 1 ? lastMessageRef : null}
        >
          <img
            src={message.sender === "user" ? userIcon : logosvg}
            alt="Avatar"
            className="avatar"
          />
          <div className="message-content">
            <p className="name">{message.sender === "user" ? "You" : "Adam"}</p>
            <div className="text">
              {message.text ? (
                <span className="streaming-text">
                  {parseMarkdown(message.text)}
                </span>
              ) : (
                message.sender === "bot" && <TypingIndicator />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatWindow;
